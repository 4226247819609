import React from "react"
import { Link } from "gatsby"
import "../../css/typography.css"
import useWindowSize from "../../hooks/useWindowSize"
import styled from "styled-components"
import FooterImg from "../../images/layout/Footer.webp"
import FooterMov from "../../images/layout/FooterMob.webp"

const FooterDiv = styled.div`
    background-image: url(${FooterImg});
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 3% 1.5% 3%;
    color: white;
    font-family: "Regular";
    font-size: 16px;
    background-color: #B6D2DD;

    >div:nth-child(1) {
        display: flex;
        align-items: center;
    }

    .footer-separator {
        height: 15px;
        width: 1px;
        border-right: 1px solid white;
        margin: 0 10px 0 10px;
    }

    a {
        color: white;
        text-decoration: none;
    }

    @media only screen and (min-width: 1800px) {
        font-size: 30px;
    }
`

const FooterDivMob = styled.div`
    background-image: url(${FooterMov});
    background-color: #B6D2DD;
    display: flex;
    flex-direction: column;
    color: white;
    font-family: "Regular";
    font-size: 16px;
    padding: 7% 3% 1.5% 3%;
    align-items: center;

    >div:nth-child(1) {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    a {
        color: white;
        text-decoration: none;
    }

    .footer-separator-mob {
        height: 15px;
        width: 1px;
        border-right: 1px solid white;
        margin: 0 10px 0 10px;
    }

    p {
        margin-bottom: .2px;
    }
`

const Footer = () => {
    const windowSize = useWindowSize();

    return windowSize > 752 ? (
        <>
            <FooterDiv>
                <div>
                    <Link to='/aviso-de-privacidad'>Aviso de privacidad</Link>
                    <div className="footer-separator"></div>
                    <Link to='/cookies'>Política de cookies</Link>
                </div>
                <p>© 2022 Gabriella Unikel. Todos los derechos reservados.</p>
            </FooterDiv>
        </>
    ) : (
        <>
            <FooterDivMob>
                <div>
                    <Link to='/aviso-de-privacidad'>Aviso de privacidad</Link>
                    <div className="footer-separator-mob"></div>
                    <Link to='/cookies'>Política de cookies</Link>
                </div>
                <p>© 2022 Gabriella Unikel.</p>
                <p> Todos los derechos reservados.</p>
            </FooterDivMob>
        </>
    )
}

export default Footer
