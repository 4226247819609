import React from "react";
import styled from "styled-components";
import stars_top from "../../images/contact/stars_top.webp";
import stars_bottom from "../../images/contact/stars_bottom.webp";
const StyledForm = styled.div`
  background-color: var(--sand);
  background-color: ${(props) => (props.white ? "var(--blue-aqua)" : null)};
  padding: 10px 20px;
  h1 {
    text-align: center;
    font-size: 32px;
    color: var(--blue-strong);
    margin-bottom: 60px;
    font-family: "S Bold";
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Regular";
    input {
      width: 100%;
      max-width: 711px;
      min-height: 52px;
      margin-bottom: 18px;
      border: none;
      padding: 15px;
      background: #b6d2dd 0% 0% no-repeat padding-box;
      ::placeholder {
        color: var(--black-1st);
      }
      :last-child {
        max-width: 180px;
        height: 52px;
        font-family: "S Normal";
        display: flex;
        justify-content: center;
        margin: 18px auto 5px;
        background: var(--blue-strong) 0% 0% no-repeat padding-box;
        color: var(--white);
        cursor: pointer;
      }
    }
    select {
      width: 100%;
      max-width: 711px;
      min-height: 52px;
      margin-bottom: 18px;
      border: none;
      padding: 15px;
      background: #b6d2dd 0% 0% no-repeat padding-box;
      font-family: "Regular";
      font-size: 14px;
      color: var(--black-1st);
    }
    textarea {
      width: 100%;
      max-width: 711px;
      min-height: 152px;
      resize: none;
      background: #b6d2dd 0% 0% no-repeat padding-box;
      border: none;
      opacity: 1;
      padding: 15px;
      font-family: "Regular";

      ::placeholder {
        color: var(--black-1st);
      }
    }
  }
  .stars_bottom {
    display: flex;
    justify-content: flex-end;
  }
  @media (min-width: 992px) {
    h1 {
      font-size: 37px;
    }
    form {
      input {
        font-size: 24px;
      }
      textarea {
        font-size: 24px;
        height: 258px;
      }
      select {
        font-size: 24px;
      }
    }
    img {
      width: 180px;
    }
    .stars_bottom {
      display: flex;
      justify-content: flex-start;
    }
  }
  @media (min-width: 1200px) {
    .stars_top {
      position: absolute;
      margin-top: 10%;
    }
    h1 {
      margin-top: 90px;
    }
  }
  @media (min-width: 1400px) {
    h1 {
      font-size: 43px;
    }
    form {
      input {
        max-width: 811px;
        margin-bottom: 25px;
        :last-child {
          max-width: 230px;
          margin-top: 30px;
          height: 62px;
        }
      }
      textarea {
        max-width: 811px;
      }
      select {
        max-width: 811px;
      }
    }
  }
  @media (min-width: 1800px) {
    height: 100%;
    min-height: 80vh;
    form {
      input {
        max-width: 950px;
        margin-bottom: 25px;
        :last-child {
          max-width: 260px;
        }
      }
      textarea {
        max-width: 950px;
      }
      select {
        max-width: 950px;
      }
    }
  }
`;
export default function Form() {
  return (
    <StyledForm>
      <div className="stars_top">
        <img src={stars_top} alt="stars_image" />
      </div>

      <h1>Informes sobre cursos</h1>
      <form action="https://formspree.io/f/mlevlrrb" method="POST">
        <input type="text" name="name" placeholder="Nombre" />
        <input type="text" name="reply_to" placeholder="Correo" />
        

        <select>
          {options.map((e, idx) => {
            return <option key={idx}>{e}</option>;
          })}
        </select>

        <textarea placeholder="Mensaje" />
        <input type="submit" value={"ENVIAR"} />
      </form>
      <div className="stars_bottom">
        <img src={stars_bottom} alt="stars_image" />
      </div>
    </StyledForm>
  );
}

const options = ["Curso de interés", "Existencialismo", "Friedrich Nietzsche", "La vida líquida", "Albert Camus", "Así Habló Zaratustra,", "Viaje a Ixtlán"];
