import React, { useState } from "react";
import Logo from "../../images/Logo.png";
import Menu from "../../images/icons/Menu.webp";
import { Link } from "gatsby";
import "../../css/typography.css";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import scrollTo from "gatsby-plugin-smoothscroll";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const HeaderTag = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: #e3d8c5;
  align-items: center;

  nav {
    display: flex;
    justify-content: space-between;
    width: 30%;
    padding: 3% 10% 2% 2%;
  }

  nav a,
  nav p {
    font-family: "Regular";
    color: #1a1a1a;
    text-decoration: none;
    font-size: 20px;
    cursor: pointer;
  }

  > div:nth-child(1) img {
    width: 100%;
  }

  > div:nth-child(1) a {
    width: 3.5rem;
  }

  > div:nth-child(1) span {
    ont-family: "S Bold";
    font-size: 2rem;
    margin: 0;
    color: var(--gray-text);
    padding: 0 0.5rem;
  }

  > div:nth-child(1) {
    padding: 1% 1% 1% 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

  }

  @media only screen and (min-width: 1700px) {
    > div:nth-child(1) img {
      width: 60%;
    }
    /* nav a {
            font-size: 35px;
        } */
  }

  @media only screen and (min-width: 2900px) {
    > div:nth-child(1) img {
      width: 80%;
    }
    nav a {
      font-size: 50px;
    }
  }
  @media only screen and (min-width: 4000px) {
    > div:nth-child(1) img {
      width: 90%;
    }
    nav a {
      font-size: 55px;
    }
  }
  @media only screen and (max-width: 1040px) {
    nav {
      width: 45%;
    }
  }
  @media only screen and (max-width: 900px) {
    nav {
      display: flex;
      justify-content: space-between;
      width: 50%;
      padding: 3% 10% 2% 2%;
    }
  }
  @media only screen and (max-width: 752px) {
    > div:nth-child(1) img {
      width: 12%;
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      padding: 0 0.5rem 0 0;
    }
    > div:nth-child(2) a {
      width: 3rem;
      
    }
    > div:nth-child(2) img {
      width: 100%;
    }

    > div:nth-child(2) span {
      ont-family: "S Bold";
      font-size: 1.25rem;
      margin: 0;
      color: var(--gray-text);
      padding: 0 0.5rem;
    }

    > div:nth-child(1) {
      padding: 3% 3% 3% 5%;
    }
  }
`;

const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  background-color: #e3d8c5;
  padding: 5% 0% 3% 8%;

  a,
  p {
    text-decoration: none;
    color: #1a1a1a;
    font-family: "Regular";
    font-size: 18px;
    margin-bottom: 18px;
    cursor: pointer;
  }

`;

const Header = () => {
  const windowSize = useWindowSize();
  const [nav, setNav] = useState(false);

  return windowSize > 752 ? (
    <HeaderTag>
      <div>
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <span>
          Mente liquida
        </span>
      </div>
      <nav>
        <AnchorLink to="/#sobre-mi-scroll" stripHash>
          <p>Sobre mí</p>
        </AnchorLink>
        <AnchorLink to="/#cursos-scroll" stripHash>
          <p>Cursos</p>
        </AnchorLink>
        <Link to="/contacto">Contacto</Link>
      </nav>
    </HeaderTag>
  ) : (
    <div>
      <HeaderTag>
        <div>
          <img src={Menu} nav={nav} onClick={() => setNav(!nav)} />
        </div>

        <div>
          <span>
            Mente liquida
          </span>
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>

        </div>
      </HeaderTag>
      {nav ? (
        <MobileNav>
          <AnchorLink to="/#sobre-mi-scroll" stripHash>
            <p>Sobre mí</p>
          </AnchorLink>
          <AnchorLink to="/#cursos-scroll" stripHash>
            <p>Cursos</p>
          </AnchorLink>
          <Link to="/contacto">Contacto</Link>
        </MobileNav>
      ) : null}
    </div>
  );
};

export default Header;
