import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Form from "../components/contact/Form";

const ContactPage = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted ? <Layout> <Form/> </Layout> : null;
};

export default ContactPage;

export const Contact = () => <title>Contact Page</title>;

//Cambiar nombre a contacto